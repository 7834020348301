import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../settings/config.json";
import Workflows from "../components/PMS/Workflows";
import ProjectStatus from "../components/PMS/ProjectStatus";
import ProjectLabels from "../components/PMS/ProjectLabels";
import EmployeeListTabClient from "../pages/EmployeeList/EmployeeListTabClient.js";
import { getRoles } from "../util/hasPermission.js";
import Unauthorised from "../components/Unauthorised/Unauthorised.js";
import MylogtimeWidget from "../pages/Mylogtime/MylogtimeWidget";
const ProgressBoardofProject = React.lazy(() =>
  import("../components/PMS/ProgressBoardofProject")
);
const ProjectTechnologies = React.lazy(() =>
  import("../components/ManageCategory/ProjectTechnologies")
);
const TrashIndex = React.lazy(() =>
  import("../pages/TrashModule/MainTrashBoard")
);
const ManageProjectType = React.lazy(() =>
  import("../components/PMS/ManageProjectType")
);
const WorkflowTasksUpdate = React.lazy(() =>
  import("../components/PMS/WorkflowTasksUpdate")
);
const Resource = React.lazy(() =>
  import("../components/PMS/Resources/Resource")
);
const ProjectArchieved = React.lazy(() =>
  import("../components/PMS/ProjectArchieved/index")
);
const AssignProject = React.lazy(() =>
  import("../components/AssignProject/AssignProject")
);
const Library = React.lazy(() => import("../components/PMS/Library"));
const EmployeeMasterList = React.lazy(() =>
  import("../pages/EmployeeList/EmployeeMasterList")
);
const ProjectsRunningReports = React.lazy(() => import("../pages/Reports"));
const TimeSheetReports = React.lazy(() => import("../pages/Reports/TimeSheet"));
const PermissionModule = React.lazy(() => import("../pages/PermissionModule"));
const ResourcePermission = React.lazy(() =>
  import("../components/PMS/Resources/ResourcePermission")
);
const DashboardModule = React.lazy(() => import("../pages/Dashbaord"));
const index = ({ match, userPermission }) => {
  const routeArray = [
    {
      path: "manage-project-type",
      component: ManageProjectType,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "project-technologies",
      component: ProjectTechnologies,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "workflows-tasks/:id",
      component: WorkflowTasksUpdate,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "workflows",
      component: Workflows,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "resources",
      component: Resource,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "project-archieved",
      component: ProjectArchieved,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.CLIENT,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },
    {
      path: "roles-permission/:id",
      component: ResourcePermission,
      roleName: [config.PMS_ROLES.SUPER_ADMIN],
    },
    {
      path: "project-users",
      component: EmployeeMasterList,
      roleName: [config.PMS_ROLES.SUPER_ADMIN],
    },
    {
      path: "project-users/client",
      component: EmployeeListTabClient,
      roleName: [config.PMS_ROLES.SUPER_ADMIN],
    },
    {
      path: "project-labels",
      component: ProjectLabels,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "my-library",
      component: Library,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "project-list",
      component: AssignProject,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.CLIENT,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },
    {
      path: "project-list/edit/:editProjectId",
      component: AssignProject,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },
    {
      path: "trash",
      component: TrashIndex,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "project/app/:projectId",
      component: ProgressBoardofProject,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.CLIENT,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },
    {
      path: "project-status",
      component: ProjectStatus,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "my-log-time",
      component: MylogtimeWidget,
      roleName: [ config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.CLIENT,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL],
    },
    {
      path: "project-runnig-reports",
      component: ProjectsRunningReports,
      roleName: [config.PMS_ROLES.SUPER_ADMIN,config.PMS_ROLES.ADMIN],
    },
    {
      path: "timesheet-reports",
      component: TimeSheetReports,
      roleName: [config.PMS_ROLES.SUPER_ADMIN,config.PMS_ROLES.ADMIN],
    },
    {
      path: "permission-access",
      component: PermissionModule,
      roleName: [config.PMS_ROLES.SUPER_ADMIN, config.PMS_ROLES.ADMIN],
    },
    {
      path: "dashboard",
      component: DashboardModule,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },
    // {
    //   path: "unauthorised",
    //   component: Unauthorised,
    //   roleName: [config.PMS_ROLES.ADMIN,
    //     config.PMS_ROLES.USER,
    //     config.PMS_ROLES.CLIENT,
    //     config.PMS_ROLES.PC,
    //     config.PMS_ROLES.TL,],

    // }
  ];

  return (
    <>
      {routeArray.map((item, index) => (
        <Route
          exact
          key={index}
          path={`${match.url}${item.path}`}
          render={(
            routeProps // Here routeProps are the props provided by the Route component (match, location, history)
          ) =>
            getRoles(item.roleName) ? (
              React.createElement(item.component, { ...routeProps }) // Spread routeProps to pass down to the component
            ) : getRoles(["Client"]) ? (
              <Redirect to="/project-list" />
            ) : (
              <Redirect to="/dashboard" />
            )
          }
        />
      ))}
    </>
  );
};

export default index;
